import React, {useState} from "react";

import "./CompareWidget.styles.sass";
import CalcSvg from "../../assets/images/icons/kalkulator-white-red-dot-middle.svg";
import Button from "../Button/Button";

// import DateFnsUtils from "@date-io/date-fns";
// import pl from "date-fns/locale/pl";
// import {addYears, format} from "date-fns";
// import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";

interface ICompareWidgetProps {
  isNarrow?: boolean;
}
const CompareWidget: React.FC<ICompareWidgetProps> = ({isNarrow = false}: ICompareWidgetProps) => {
  // const [dateOfBirth, setDateOfBirth] = useState<Date | null>(null);
  const [age, setAge] = useState("0")
  const [amount, setAmount] = useState("0");

  return (
    <div className="calc py-section">
      <div className="bg">
        <div className="row">
          <div className={`${isNarrow ? "col-12" : "col-lg-12 mb-3 "} d-flex align-items-center`}>
            <img className={`${isNarrow ? "icon-big" : "icon"} `} src={CalcSvg} alt="Kalkulator IKZE" />
            <h2>Oblicz wysokość swojej dodatkowej emerytury z IKZE</h2>
            <div className="line-calc"></div>
          </div>
        </div>

        <div className="row pt-4">
          <div className={`${isNarrow ? "col-12" : "col-lg-4 col-md-12"} `}>
            <div className="input-wrapper">
              <label htmlFor="age">Ile masz lat?</label>
              {/* <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pl}>
                <DatePicker
                  value={dateOfBirth}
                  variant="inline"
                  autoOk={true}
                  minDate={addYears(new Date(), -59)}
                  maxDate={addYears(new Date(), -18)}
                  openTo="year"
                  disableToolbar
                  orientation="landscape"
                  format="y-MM-dd"
                  views={["year", "month", "date"]}
                  onChange={(date) => setDateOfBirth(date)}
                />
              </MuiPickersUtilsProvider> */}
              <input
                name="age"
                type="number"
                inputMode="numeric"
                autoComplete="off"
                min="18"
                max="59"
                onChange={(e) => {
                  setAge(e.target.value);
                }}
              />
            </div>
          </div>
          <div className={`${isNarrow ? "col-12 mt-3" : "col-lg-4 col-md-12 pt-3 pt-lg-0"} `}>
            <div className="input-wrapper">
              <label htmlFor="value">Jaką kwotę chcesz oszczędzić?</label>
              <input
                name="value"
                type="number"
                inputMode="numeric"
                autoComplete="off"
                onChange={(e) => {
                  setAmount(e.target.value);
                }}
              />
            </div>
          </div>
          <div className={`${isNarrow ? "col-12 mt-3" : "col-lg-4 col-md-12 pt-3 pt-lg-0"} d-flex align-items-center `}>
            <Button title="Oblicz" width="100%" padding="22px 30px" internalLink={age ? `/porownanie-ofert-ikze/?wiek=${age}&kwota_oszczednosci=${amount}` : "/porownanie-ofert-ikze/"} />
            {/* <Button title="Krok drugi" width="100%" padding="22px 30px" internalLink={dateOfBirth ? `/porownanie-ofert-ikze/?data_urodzenia=${format(new Date(dateOfBirth as Date), "yyyy-MM-dd")}&kwota_oszczednosci=${amount}` : "/porownanie-ofert-ikze/"} /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompareWidget;
