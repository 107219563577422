import React from 'react'
import { default as Sticky } from 'react-sticky-box'

interface IStickyBoxProps{
  children: React.ReactNode
}

export default function StickyBox({children}: IStickyBoxProps) {
  return (
    <Sticky offsetTop={20} offsetBottom={20}>
      {children}
    </Sticky>
  )
}
