import {StaticImage} from "gatsby-plugin-image";
import React from "react";
import Layout from "../component/Layout/Layout";

import Hero from "../component/Hero/Hero";

import BlogPosts from "../component/Posts/BlogPosts";
import StickyBox from "../component/StickyBox/StickyBox";
import "../assets/styles/article-page.sass";
import CompareWidget from "../component/CompareWidget/CompareWidget";
import Seo from "../component/Seo/Seo";
import {useStaticQuery, graphql} from "gatsby";
import Pit from "../assets/images/pit.jpg"
import PrognozowanyRocznyLimitWplatNaIkze from "../assets/images/prognozowany_roczny_limit_wpalt_na_ikze.jpg"
import SzacowanaWysokoscZwrotuPodatku from "../assets/images/szacowana_wysokosc_zwrotu_podatku.jpg"

const TaxRelief: React.FC = () => {
  const {wpPage} = useStaticQuery(graphql`
  query TaxReliefSeo {
    wpPage(slug: {eq: "ulga-podatkowa"}) {
      seo {
        metaDesc
        title
        focuskw
      }
      opisStrony {
          opis
        }
    }
  }
`);
  return (
    <Layout>
       <Seo title={wpPage.seo.title} description={wpPage.seo.metaDesc} keywords={wpPage.seo.metaDesc} pathname="/ulga-podatkowa/" />
      <Hero
        heading={`Ulga podatkowa z IKZE`}
        image={<StaticImage src="../assets/images/photo/hero-tax.jpg" height={600} width={828} quality={90} alt="Ulga podatkowa" title="Ulga podatkowa" />}
        paragraph={wpPage.opisStrony.opis}
      />
      <div className="container mw-1400">
        <div className="row">
          <div className="col-xl-8 article">
            <h2 className="first">Jakie są podatkowe korzyści z oszczędzania w IKZE?</h2>
            <div className="line"></div>
            <p className="text">
              Jedną z najważniejszych zalet oszczędzania w ramach IKZE są korzyści podatkowe. Te z kolei możemy czerpać na dwa sposoby. Pierwszy to fakt, że IKZE jest parasolem podatkowym, a więc w trakcie oszczędzania nie dotyczy nas 19% podatek od zysków kapitałowych, czyli tzw. podatek Belki. To oznacza, że otrzymujemy większy kapitał pracujący na zysk w przyszłości, co w długookresowej perspektywie – a o taką przecież chodzi – przekłada się na ilość zaoszczędzonych pieniędzy.
            </p>
            <p className="text">
              Druga korzyść to możliwość odliczenia sobie wysokości wpłaty dokonanej na IKZE od podstawy opodatkowania. Ile konkretnie możemy na tym zyskać? Dokonajmy uproszczonych (darujemy sobie odliczanie składek na ubezpieczenia) obliczeń. 
            </p>
            <p className="text">
              Według GUS przeciętne wynagrodzenie w gospodarce narodowej wyniosło w 2020 roku 5167,47 zł brutto – dla prostoty obliczeń przyjmijmy 5100. Rocznie daje nam to więc 61 200 zł. Przyjmijmy, że zarabiającemu tyle pracownikowi uda się wygospodarować w tym roku 6300 zł do wpłacenia na swój III filar, a więc niemal tyle, ile wynosi roczny limit wpłat IKZE w 2021 r.
            </p>
            <p className="text">
              <strong>Jak to się przekłada na konkretne oszczędności wg skali podatkowej na lata 2020-2021 (a więc w tym wypadku 17%)?</strong> Bez wpłaty na IKZE od podstawy wynoszącej 61 200 zapłacimy, po odjęciu kwoty zmniejszającej podatek, 9 878,88 zł. Dokonując wpłaty na IKZE, zmniejszymy tę podstawę do 54 900 zł, co ostatecznie daje podatek w wysokości 8 807,88 zł. W kieszeni zostaje nam więc 1071 zł. Ostatecznie wpłacenie na swoją emeryturę ponad 6 tys. zł kosztowało nas ponad 5. Czyż to się nie opłaca?
            </p>
            <p className="text">
              <strong>A ile zyskują pracownicy znacznie lepiej wynagradzani, objęci drugim progiem podatkowym, a więc winni państwu 32% swojego dochodu?</strong> Pensja 11 tys. brutto daje nam podstawę w wysokości 132 tys. zł. Bez wpłaty na IKZE zapłacimy 42 240 zł. Podstawa zmniejszona o taką samą wpłatę na IKZE bliską maksymalnej oznacza konieczność odprowadzenia 40 224 zł. W kieszeni zostaje nam więc 2016 zł. W przypadku wysokich zarobków być może nie robi już ona wrażenia, ale, tak czy inaczej, zyskujemy i w tym wypadku koszt odłożenia środków na emeryturę maleje o niemal jedną trzecią.
            </p>
            <p className="text">
              <strong>Sumę środków odprowadzonych w danym roku podatkowym na IKZE możemy odliczyć od podstawy opodatkowania także wówczas, gdy np. prowadzimy działalność gospodarczą i odprowadzamy podatek liniowy wynoszący 19%.</strong> Jak łatwo policzyć, dla tego typu podatników, taka sama wpłata na IKZE niemal wyczerpująca limit w 2021 r., a więc 6300 zł, oznacza 1197 zł zwrotu. Możliwość jej odliczenia towarzyszy również opodatkowaniu ryczałtem od przychodów ewidencjonowanych – w taki sytuacji wysokość wpłaty na IKZE odliczamy od przychodu.
            </p>


            <h2>W jaki sposób odliczyć wpłaty na IKZE od podatku? To proste!</h2>
            <div className="line"></div>
            <p className="text">
              Jeśli składamy standardowy formularz PIT-37, PIT-36 bądź PIT-28 (dotyczy podatników rozliczający się według skali podatkowej, liniowo, a także ryczałtem), można dołączyć do niego dodatkowy dokument w postaci formularza PIT/O, gdzie przygotowana została specjalna rubryka na wpłaty na IKZE.
            </p>
            <img src={Pit} alt="Pit" className="img-w-100" />
            <p className="text">
              Inaczej sprawa przedstawia się w przypadku podatników korzystających z formularza PIT-36L, gdzie odliczeń dokonuje się bezpośrednio i nie dołącza się PIT/O. Przed jeszcze prostszym zadaniem stają podatnicy korzystający z internetowego formularza Twój e-PIT, który przeprowadza użytkownika przez wszystkie czynności.<br /><br /><strong>Należy pamiętać, że aby odliczyć wpłaty na IKZE od podatku, potrzebujemy jeszcze dowodów wpłat zaksięgowanych przed końcem roku, za który się rozliczamy.</strong>
            </p>

            <h4 className="mt-4">Kto nie może odliczyć sobie wpłat na emeryturę w ramach rachunku IKE?</h4>
            <p className="text">
              Przede wszystkim podatnicy stosujący PIT-16A (kartę podatkową), rozliczający dochody z przychodów kapitałowych w PIT-38 czy z opodatkowanych ryczałtowo umów.
            </p>
            <h3 className="mt-4">Czy wszystko to oznacza, że oszczędzając w IKZE nie zapłacimy żadnych podatków?</h3>
            <p className="text">
              Przy wypłacie środków czeka nas zapłata 10% ryczałtowego podatku od ich całości. To niemało. Może się wydawać, że taka perspektywa wręcz niweczy sens oszczędzania na takim rachunku. Nic bardziej mylnego! Należy pamiętać o wartości otrzymywanych co roku zwrotów podatku. Najlepszym pomysłem będzie inwestowanie tych pieniędzy w ramach jakiegoś innego narzędzia (np. IKE, również chroniącym nas przed ministrem Belką) i wówczas mamy możliwość radykalnego podniesienia efektywności oszczędzania na emeryturę przy tej samej sumie środków.
            </p>

            <h2>Jak przyszłość czeka za 10 lub 15 lat tych, którzy w pełni wykorzystują limit wpłat IKZE?</h2>
            <div className="line"></div>
            <p className="text">
              Wpłacamy na IKZE maksymalne roczne kwoty. Ile zyskamy za 5 lub 10 lat? Od 2014 roku limit wpłat na IKZE rósł o średnio 5% rocznie. Co nas zatem czeka, jeśli trend wzrostu wynagrodzeń się utrzyma? Załóżmy, że możemy spodziewać się takiego wzrostu:
            </p>
            <img src={PrognozowanyRocznyLimitWplatNaIkze} alt="Prognozowany roczny limit wpłat na IKZE" className="img-w-100" />
            <p className="text">
              A co za tym idzie, wartość możliwych zwrotów podatków dla osób mieszczących się w pierwszym, 17%, progu podatkowym (zakładamy, że się on nie zmieni), może przedstawiać się następująco:
            </p>
            <img src={SzacowanaWysokoscZwrotuPodatku} alt="Szacowana wysokość zwrotu podatku" className="img-w-100" />
            <p className="text font-bold">
              A zatem: jeśli założyć prawidłowość tej prognozy, to gdy zaczniemy oszczędzać jeszcze w 2021 roku, samego kapitału możemy prawdopodobnie zebrać:
            </p>
            <ul className="list-element">
              <li>za 5 lat: 34 871,16 zł</li>
              <li>za 10 lat: 79 376,56 zł</li>
              <li>za 15 lat: 136 178 zł</li>
            </ul>
            <p className="text">
              Wystarczy więc, że dziś zacznie oszczędzać nawet 50-latek, by, zakładając, że chce potem korzystać z tych środków do 85 roku życia (przy czym wg GUS średnia długość życia to 74,1 lat dla mężczyzn i 81,7 lat dla kobiet, ale bądźmy optymistami – generalnie jedna i druga rośnie), mógł dysponować choćby kilkuset złotowym dodatkiem do emerytury. Jednak my przecież tego czasu możemy dać sobie znacznie więcej!
            </p>
            <p className="text">Z kolei rosnące z wolna także zwroty podatkowe będą szacunkowo wynosić:</p>
            <ul className="list-element">
              <li>za 5 lat: 6 224,50 zł</li>
              <li>za 10 lat: 14 168,71 zł</li>
              <li>za 15 lat: 22 077,41 zł (licząc do zwrotu w roku 2036 za rok 2035)</li>
            </ul>
            <p className="text">
              Wszystko to jest jedynie sumą kapitału – a każdy z produktów finansowych, jaki możemy „ubrać” w nasz rachunek IKZE, pozwala przecież na pewien zysk. Jak nietrudno obliczyć, zakładając, że przez 15 lat odkładalibyśmy 6500 zł przy jedynie 3% zysku i braku podatku od zysków kapitałowych, to obok 97 500 zł samego kapitału, uzyskalibyśmy 27 000 zł odsetek.
            </p>
            <h2>Możesz nie martwić się o przyszłość, jeśli sprawisz, by pracował dla Ciebie czas!</h2>
            <div className="line"></div>
            <p className="text">
              Skromny na pierwszy rzut oka roczny limit wpłat na IKZE daje nam więc bardzo duże możliwości. Jest to najprostsze i najlepsze rozwiązanie, jakie bez ponoszenia ogromnych wydatków ze strony państwa można było przygotować dla milionów Polaków. 
            </p>
            <p className="text">
              IKZE chroni przed obciążeniem podatkami tych, którzy będą w stanie wygospodarować sobie nawet relatywnie niewielkie środki. Wymaga od nich tylko jednego: systematyczności i cierpliwości; żelaznej woli w dążeniu do celu. 
            </p>
            <p className="text">
              I nie ma innej drogi, także wówczas, gdy zechcemy skorzystać z innych dostępnych dziś programów emerytalnych, np. towarzyszących ubezpieczeniom. W IKZE musimy tylko sami pamiętać o zasilaniu rachunku – nic innego za to nie może się z nim równać pod względem efektywności wykorzystywania kierowanych na niego pieniędzy. Sprawdź więc nasz Ranking IKZE i wykorzystaj kalkulator, aby zaplanować swój własny program emerytalny!
            </p>
          </div>
          <div className="col-xl-4">
            <StickyBox>
              <CompareWidget isNarrow={true} />
            </StickyBox>
          </div>
        </div>
        <div className="row">
          <BlogPosts />
        </div>
      </div>
    </Layout>
  );
};

export default TaxRelief;
