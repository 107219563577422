import React from "react";
import {getImage} from "gatsby-plugin-image";
import {graphql, useStaticQuery} from "gatsby";
import Slide from "../Slide/Slide.jsx";
import Post from "../Posts/Post.jsx";

const BlogPosts: React.FC = () => {
  const data = useStaticQuery(query);
  const posts = data.allWpPost.edges;

  return (
    <Slide title="Blog emerytalny" isBackgroundColor={false}>
      {posts.map((post: any, index: number) => {
        const image = getImage(post.node.featuredImage.node.localFile);
        return <Post post={post.node} image={image} key={index} width="85%" type="blog" />;
      })}
    </Slide>
  );
};

export default BlogPosts;

const query = graphql`
query last10BlogPosts {
  allWpPost(
    sort: {order: DESC, fields: dateGmt}
    filter: {categories: {nodes: {elemMatch: {slug: {eq: "blog-emerytalny"}}}}}
    limit: 10
  ) {
    edges {
      node {
        dateGmt(formatString: "DD MMMM YYYY", locale: "pl")
        id
        title
        excerpt
        slug
        featuredImage {
          node {
            altText
            mediaItemUrl
            localFile {
              childImageSharp {
                gatsbyImageData(width: 700, height: 400)
              }
            }
          }
        }
        modifiedGmt(formatString: "DD.MM.YYYY")
        zajawkablog {
          zajawka
        }
      }
    }
  }
}

`;
